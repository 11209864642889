<template>
<div class="app" >
<navs /> 
<classQuestions :key="this.$route.params.page"/>
<footers/> 
</div>
</template>
<script >

import navs from '../../components/nav.vue'
import footers from '../../components/footers.vue'
import classQuestions from '../../components/classQuestions.vue'
export default {
    name: 'questions',
    components: {
        navs,
        footers, 
        classQuestions,
    },

    mounted() {
        console.log('重新')
      
    },

    data() {
        return {
            
        }
    }
}
</script>
<style lang="scss" scoped >


</style>