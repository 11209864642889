

<template>
  <div class="app" >
    <navs /> 
    <homeMain />
    <footers/>
   
  </div>
</template>

<script>
import homeMain from '../../components/main.vue'
import navs from '../../components/nav.vue'
import footers from '../../components/footers.vue'
export default {
  components: { homeMain, navs, footers },
  name: 'home'
}

</script>

<style lang="scss">


</style>
