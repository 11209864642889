<template>
    <div class="container">
       <button @click="goToLogin">登录</button>
    </div>
</template>

<script>
import {mapState,mapMutations,mapActions,mapGetters} from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    mounted() {
        
        
        // try {
            
        //     this.goToLogin()
            
       
        // } catch (error) {
        //     this.$alert(error.message);        
        // }

        
    },

    methods: {
        ...mapActions('users',['authLogin']),

        goToLogin(){
            this.authLogin({
                'username': 'admin',
                'password': '123456', 
            }).then(res => {
                if (res == 'success') {
                    // 登录成功跳转页面
                    return this.$router.push({name: 'home'});
                   
                }

                return this.$alert(res.message)

                }).catch(res=>{
                this.$alert("登录失败") 
            })
        }

        
        

       
    }
}
</script>

<style scoped>

</style>