import { reqCategoryList } from "@/api";
import {setCache,getCache,removeCache} from "@/utils/cache";

export default {
 // 命名空间
 namespaced: true,
 state: () => ({
  list: {},
  subCategory: {},
  parentId:0,
 }),

 mutations: {
  //  设置类别
  setList(state,list){
    state.list = list
  },
  //  设置子类
  setSubCategory(state,subCategory){
    state.subCategory = subCategory
  },

  // 改变分类按钮状态
  setParentId(state,id){
    state.parentId = id
  }
 
 },
 actions: {
  //  获取类别
  async getCategoryList({commit},data){
   
    const result = await reqCategoryList(data.parent_id)
    if(result.status == 'success'){
    
      if (data.parent_id == 0) {
        commit('setList',result.data)
        for (let i = 0; i < result.data.length; i++) {
          let id = result.data[i].id;
         
          // 是否有子集
          let children = result.data[i].children??{};
          
          if (children.length) {
            // commit('setParentId',result.data[i].id)
            commit('setSubCategory',children)
            break;
          }
        }
    
      } else {
        console.log(result.data[0].children);
        // commit('setParentId',data.parent_id)
        commit('setSubCategory',result.data[0].children)
      }
      console.log(result);
     
    }
    return result.status
  }

 },
 getters: {
  //  获取类别
  getClassList(state){
    return state.classList
  }
 }

}