<template>
  <div class="container">
    

    <!-- 主体 -->
     <div class="main">
      <!-- 标签 -->
      <div class="tag col-xl col-lg col-md col-sm">
       
        <div v-for="(item, index) in list" :key="index">
          <div class="tag-row" @click = "getSubCategory(item.id)">
            <div  v-if = "item.id == parentId"  class="tag-row-active">
              {{ item.name }}
            </div>
            <div v-else>
              {{ item.name }}
            </div>
            
          </div>
        </div>
        
      
      </div>
      <!-- 类别 -->
      <div class="category">
        
        <div v-for="(item, index) in subCategory">
          <RouterLink :to="{name: 'classQuestions', params: {id:item.id,page:1}}">
            <div class="category-row" >
          <img class="category-img" :src="item.icon" alt="" srcset="">
          <div class="category-text">
            <div>{{item.name}}</div>
            <div>
              {{ item.intro }}
            </div> 
          </div>
        </div>
            
          </RouterLink>
          

        </div>
        
      </div>

        

     </div>

    
  </div>
  
</template>

<script>
   import {mapState,mapMutations,mapActions,mapGetters} from 'vuex'
   import {setCache,getCache,removeCache} from "@/utils/cache";
  //  import '@/styles/main.scss';
   export default {
    data() {
      return {
       

      }
    },

    mounted() {

      try {
             
          this.getList()
      } catch (error) {
          this.$alert(error.message);        
      }

    },
    computed: {
      ...mapState('category',['list','subCategory','parentId']), 
    },
    
    methods: {
      ...mapActions('category',['getCategoryList']),
      // 获取子类
      getSubCategory($id) {
        
        this.getList($id)
      },
  
      getList($parentId = 0){
            this.getCategoryList({
              'parent_id':$parentId
            }).then(res => {
                if (res == 'error') {
                    return this.$alert(res.message)
                   
                }
                }).catch(res=>{
                  console.log(res);
                  this.$alert("服务器错误，请稍后再试！！！") 
                })
      },

    
      goToQuestions($id) {
        this.$router.push({name: 'classQuestions', params: {id:$id,page:1}})
      }
    }
  }
</script>

<style lang="scss" scoped>
//  主体
.main {
 

 .tag {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   background-color: #FFFFFF;
   height: 100%;
   margin: 1rem;
   // width: 90%;
   // background: linear-gradient(to bottom right, rgba(253, 246, 239, 0.5019607843), rgba(181, 102, 245, 0.2549019608));
   border-radius: 1rem;
   .tag-row {
     cursor: pointer;
     .tag-row-active {
       color: #FFFFFF;
       border-radius: 0.5rem;
       background-color: #00c0ef;
     }

   }
   div {
     padding: 10px;
     width: auto;
   }

 }
 // 分类模块
 .category {
   display: flex;
   flex-direction: row;
   justify-content:center;
   flex-wrap: wrap;
   align-items: center;
   // width: 90%;
 
   .category-row {
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-around;
     padding: 24px;
     width: auto;
     margin: 1rem 1rem;
     border-radius: 1rem;
     border: 1px solid #f0f0f0;
     cursor: pointer;
     background-color: #FFFFFF;
     // background: linear-gradient(to bottom right, rgba(253, 246, 239, 0.5019607843), rgb(245 102 102 / 9%));
     
     .category-img {
       width:5rem;
       
     }
     
     .category-text {
       display: flex;
       flex-direction: column;
       justify-content: center;
       // width: 300px;
       margin-left: 1rem;

       div:nth-child(1) {
         font-weight: bold;
         font-size: 1rem;
       }
       div:nth-child(2) {
         width: 10rem;
         font-size: 15px;
         color: #666666;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         
 

       }
     }
     
   }
 }


}


</style>
