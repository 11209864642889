import { reqQuestionDetail,reqUserQuestionTags,reqUserQuestionCollect,reqQuestionBrowsing } from "@/api";
import router from '../router'; // 引入路由实例

export default {
  namespaced: true,
  state: () => ({
    questions: {},
   
  }),
  mutations: {
  

    setQuestion(state, questions) {
      state.questions = questions;
    },

    setTag(state, is_tag) {
      state.questions.is_tag = is_tag
    
     
    },

    setCollect(state, is_collect) {
      state.questions.is_collect = is_collect
    
    },

    setBrowsing(state,browsing) {
      state.questions.browsing = browsing 
      console.log(state.questions)
    }

  },
  actions: {
    // 获取问题详情
    async getQuestionDetail({ commit }, data) {
   
      try {

        const result = await reqQuestionDetail(data);
    
        if (result.status == "success") {
          commit("setQuestion",result.data);
        } 
        return result;
        
      } catch (error) {
        // if (error.response && error.response.data) {
        //   return error.response.data;
     
        // }
      
      }

      return {
        status: 'error',
        message: '请稍后重试',
      };
     
    },

    /**
     * 设置标识
     * @param {*} param0 
     * @param {*} data 
     * @returns 
     */
    async setQuestionTags({commit,state},data){
     
        const result = await reqUserQuestionTags(data)
        if(result.status == 'success'){
          // 获取用户I
          commit('setTag',data.is_tag)
        }

        return result; 
    },

    /**
     *  收藏
     * @param {*} param0 
     * @param {*} data 
     * @returns 
     */
    async setQuestionCollect({commit},data){
     
        const result = await reqUserQuestionCollect(data)
        if(result.status == 'success'){
          
          commit('setCollect',data.is_collect)
        }
        return result;

      
    },

    /**
     * 浏览量
     * @param {*} param0 
     * @param {*} data 
     * @returns 
     */
    async setQuestionBrowsing({commit},data){

     
        const result = await reqQuestionBrowsing(data)
        if(result.status == 'success'){
          commit('setBrowsing',result.data.browsing)
  
        } 
        return result;
    

      
    }
  },

  getters:{
   
    
  }
}
