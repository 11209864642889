<template>
    <div>
        <div v-for="(item, index) in list" :key="index">
          
            <div  :style = "item.level == 2 ? dynamicStyle : ''"  class="comment-item">
                

            <!-- 用户信息 -->
            <div class="comment-user-info">
                <!-- logo -->
                <div class="user-logo">
                    <img src="../assets/images/user-logo.png" alt=""></img>
                </div>
                <!-- 内容 -->
                <div class="comment-content">
                
                    <div class="comment-user">{{ item.userNickname }}</div>
                    <div v-if="item.parent_id > 0" class="comment-reply">
                        <div>回复</div>
                        <div> {{ item.byReplyuserNickname }}</div>
                    </div>

                    <div class="comment-time">{{item.created_at}}</div>
                    

                    <!-- 回复 -->
                    <div class="comment-content-reply" @click="replyEdit(index)">
                    <div>回复</div>
                    
                    </div>
                
                </div>
            </div>
            <!-- 编辑 -->
            <div v-if="isActive(index)" class="comment-textarea">
                <textarea class="form-control" :id="'exampleFormControlTextarea' + (index + 1)" v-model="textareas[index]" placeholder="请输入内容" rows="4"></textarea>
                <div class="btn btn-outline-info" @click="addComment(item.id,index)">回复</div>
            </div>
           
               
                <!-- 评论内容 -->
                <div class="comment-content-text">{{item.content}}</div>
                <!-- <div v-if="item.children && item.children.length">
                    
                    <commentItem :list="item.children" :isChildCommentItem="true"></commentItem>

                </div> -->
               
        
            </div>
             
        </div>
    
    </div>
  </template>
  
  <script>
    
    export default {
        
        name: 'commentItem', // 组件的名字
        data() {
            return {
                isReplyEdit: false,
                isCommentItem: true,
                textareas: [], // 每个 textarea 的值
                activeIndex: null, // 用于记录当前激活的下拉框索引
                
            };
        },
        props: {
            list: {},
            isChildCommentItem:false,
            id: String,
            label: String,
            value: String
        },
        mounted() {


        },
        computed: {
            dynamicStyle() {
                return {
                    paddingLeft: '1rem',
                    
                };
            },

            
        },
        methods: {
            async replyEdit(index) {
                
                if (this.activeIndex === index) {
                    this.activeIndex = null; // 如果当前下拉框已展开，则收起
                } else {
                    this.activeIndex = index; // 否则，展开当前下拉框
                }
            },
            // 添加评论向父组件传值
            async addComment(id,index) {

    
                this.$emit('addCommentEvent',this.textareas,id,index)
                this.replyEdit(index);

            },

            isActive(index) {
                return this.activeIndex === index; // 判断当前下拉框是否激活
            },
            
        }
    }
  
  </script>
  
  <style lang="scss" scoped>

    .comment-textarea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;
        margin-top: 1rem;
        width: 100%;
        
        textarea {
            background-color: #F8F9FB;
        }
        button {
            margin-top: 0.5rem;
        }
    }

  .comment-item {
        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        margin-top: 1rem;
    
        .comment-content-text {
            text-align: left;
            padding-left: 1rem;
            font-size: 14px;
            color: #222226;
            line-height: 22px;
            word-break: break-word;
  
        }
  
        .comment-user-info {
          display: flex;
          flex-direction: row;
          justify-content: left;
          /* align-items: center; */
          margin-bottom: 1rem;
        }
  
        .comment-content-reply {
          margin-left: 0.5rem;
          width: 100%;
          white-space: nowrap;
          text-align: end;
        }
  
        .comment-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: left;
          width: 80%;
          color: #777888;
          font-size: 14px;
          .comment-reply {
            display: flex;
            width: 100%;
            white-space: nowrap;
            
          }
        
          .comment-time {
            white-space: nowrap;
            margin-left: 0.5rem;
            width: 100%;
           
          }
        }
      }

      /* .child-comment-item {
      
        padding-left: 2rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        .comment-user {
          color: #999;
        }
      } */
  

  </style>