<template>
  <div class="search-container">
    <input
      type="text"
      v-model="searchQuery"
      placeholder="Search..."
      @keydown.enter="onSearch"
      class="search-input"
    />
    <i class="fa fa-search search-icon" @click="onSearch"></i>
  </div>
</template>

<script>
import { getCache, setCache, removeCache } from "@/utils/cache";

export default {
  data() {
    return {
      searchQuery: getCache('search')??""
    };
  },
  methods: {
    onInput(event) {

      // 处理输入事件
      console.log('Input:', event.target.value);


    },
    onSearch() {
      // 处理搜索点击事件
      setCache('search', this.searchQuery);
      this.$emit('search', this.searchQuery);
    }
  }
};
</script>

<style scoped>
.search-container {
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  padding: 8px;
  background-color: #fff;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 4px;
}

.search-icon {
  cursor: pointer;
  margin-left: 8px;
  color: #999;
  font-size: 18px;
}

.search-icon:hover {
  color: #007bff;
}
</style>