import Home  from '../pages/Home/index.vue'
import ClassQuestions from '../pages/ClassQuestions/index.vue'
import QuestionsInfo from '../pages/QuestionsInfo/index.vue'
import Login from '../pages/Login/index.vue'

export default [
    /**
     * 登录
     */
    {
        path: '/login',
        name:'login',
        component:Login,
        meta: { requiresAuth: false }, // 需要身份验证
    },

    /**
     * 首页
     */
    {
        path: '/',
        name:'home',
        component: Home,
        meta: { requiresAuth: false }, // 需要身份验证
    },

    /**
     * 问题分类
     */
    {
        path: '/category/question/:id/:page/:serach?',
        name:'classQuestions',
        component: ClassQuestions,
        meta: { requiresAuth: false }, // 需要身份验证
    },

    /**
     * 问题详情
     */
    {
        path: '/question/:category_id/:id',
        name:'questionsInfo',
        component: QuestionsInfo,
        meta: { requiresAuth: true }, // 需要身份验证
    },


]