<template>
    
    <div class="container">
      
        <!-- 答案 -->
        <div class="container-anwsers" >
        
            <div class="question">
                <div class="title">
                    {{questions.id}}.
                {{questions.question}}

                </div>
                <!-- 标签 -->
                <div class="tag" >
                    <div >简单</div>
                </div>
                 
                <div class="other">
                    
                    <div class="tags">
                        <img
                        src="@/assets/images/eye.svg"
                        alt="">
                     {{ questions.browsing}}</div>
                    <div class="tags" @click="saveCollect(questions.id,questions.is_collect)">
                        <div v-if="questions.is_collect === 1">
                            <img
                            src="@/assets/images/star-fill.svg"
                            alt=""></img>
                            已收藏
                        </div>
                        <div v-else>
                            <img
                            src="@/assets/images/star.svg"
                            alt=""></img>
                            收藏
                        </div>
                       
                        
                    </div>
                
                    <div class="tags" @click="saveTag(questions.id,questions.is_tag)">
                        <div v-if="questions.is_tag === 1">
                            <img
                            src="@/assets/images/tags-fill.svg"
                            alt=""></img>
                            已标记
                        </div>
                        <div v-else>
                            <img
                            src="@/assets/images/tags.svg"
                            alt=""></img>
                            标记
                        </div>
                        
                    </div>

                </div>
                
            </div>
            <div id = "preview" class="preview"></div>
            <!-- 翻页 -->
            <div class="page">
                
                <div v-if="Object.keys(questions.before || 0).length > 0" @click="goToQuestions(questions.before.id)">
                上一题
                
                {{ questions.before.id }} .
                {{ questions.before.question}}
                </div>
                <div v-else>
                 
                    
                </div>
               
                <div v-if="Object.keys(questions.after || 0).length > 0" @click="goToQuestions(questions.after.id)">
                    下一题
                    {{ questions.after.id }} .
                    {{ questions.after.question}}
                </div>
                <div v-else>
                </div>
            </div>

            <!-- 评论 -->
            <div class="comment">
                <!-- <img
                src="@/assets/images/chat-left-text.svg"
                alt="">
                </img> -->
                <comment></comment>
            </div>
            

        </div>
    </div>
    
</template>

<script>


import 'highlight.js/styles/github.min.css'; // 引入样式文件

import { mapState, mapGetters, mapActions } from 'vuex'
import comment from './comment.vue'
import VditorPreview from 'vditor/dist/method.min'  
import "vditor/dist/index.css"
export default {
    components: { comment },
    data() {
        return {
           
        }
    },
    computed: {
        ...mapState('question',['questions','is_collect','is_tag']),
    },
    watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
       
        this.questionDetail(to.params.id)

      }
    }
  },
    mounted() {
        let id = this.$route.params.id
        this.questionDetail(id)
        this.setBrowsing(id)
        
    },
    methods: {
        ...mapActions('question',['getQuestionDetail','setQuestionTags','setQuestionCollect','setQuestionBrowsing']),
        // 获取问题详情
         questionDetail($id) {
           
            this.getQuestionDetail({
                "id":$id,
                "category_id":this.$route.params.category_id??0
            }).then(res => {
                
                if (res.status == 'error') {
                    return this.$alert(res.message)
                } 
                this.initVditor();
            })
        },
        // 收藏
       saveCollect($id,$is_collect) {
           
            if ($is_collect === 1) {
                $is_collect = 0
            } else {
                $is_collect = 1
            }
            this.setQuestionCollect({
                "id":$id,
                "is_collect":$is_collect
            }).then(res => {
                if (res.status == 'error') {
                    return this.$alert(res.message)
                }
            })

        },
        // 标识
        saveTag($id,$is_tag) {

            if ($is_tag === 1) {
                $is_tag = 0
            } else {
                $is_tag = 1
            }

            this.setQuestionTags({
                "id":$id,
                "is_tag":$is_tag
            }).then(res => {
                if (res.status == 'error') {
                    return this.$alert(res.message)
                }
            })
        },
        // 浏览
        setBrowsing($id) {
            this.setQuestionBrowsing({
                "id":$id
            }).then(res => {
                if (res.status == 'error') {
                    return this.$alert(res.message)
                }
            })
        },

        initVditor() {

            const cdn = 'https://cdn.jsdelivr.net/npm/vditor@3.10.7'
            
            VditorPreview.preview(preview,this.questions.answer,'classic')
            
            VditorPreview.mermaidRender(preview,cdn)
            VditorPreview.codeRender(preview)
            // chartRender 图表
            VditorPreview.chartRender(preview,cdn)
            // abcRender 五线谱渲染

            VditorPreview.abcRender(preview,cdn)
            // highlightRender 代码高亮
            VditorPreview.highlightRender('dark',preview,cdn)
            // mediaRender 为特定链接分别渲染为视频、音频、嵌入的 iframe
            VditorPreview.mediaRender(preview,'classic')
            // mathRender 公式渲染
            VditorPreview.mathRender(preview,'classic')
            //speechRender 对选中的文字进行阅读
            VditorPreview.speechRender(preview,'classic')
            // graphvizRender 对 图画 进行渲染
            VditorPreview.graphvizRender(preview,'classic')
            //md2html
            VditorPreview.md2html(preview,'classic')
                
        },

        goToQuestions($id){
            
            if (!$id) {
                return this.$alert("没有获取到问题")
            }

            this.$router.push({name: 'questionsInfo', params: {id: $id,category_id:this.$route.params.category_id}})


        }
    }

    
}

</script>

<style lang="scss" scoped>

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100rem;
    .container-anwsers {
        min-width: calc(100% - 15rem);
        .comment {
            border-top: 1px solid #00000016;
            margin-top: 1rem;
            text-align: center;
            font-weight: bold;
            font-size: 1.5rem;   
            cursor: pointer;  
        }
        .question {
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            /* align-items: center;  */
            margin-top: 20px;
            border: 1px solid #f0f0f0;
            /* padding: 10px; */
            background-color: #FFFFFF;
            
            .title {
                margin-top: 1rem;
                margin-left: 1rem;
                font-weight: bold;
                font-size: 1.5rem;         
            }

            .tag {
                display: flex;
                flex-direction: row;
                /* justify-content: space-around; */
                margin-top: 5px;
                margin-left: 1.5rem;
                
                div {
                    padding: 2px;
                    background-color: #2FBDFF;
                    color: #f0f0f0;
                    border-radius: 10px;

                }
            }

            .other {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-around;
                margin-top: 10px;
                border-top: 1px solid #00000016;
                height: 3rem;
                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: 2px;
                    border-right: 1px solid #00000016;
                    padding: 2px;
                    text-align: center;
                    width: 100%;
                    color: #666666;
                    img {
                        margin-right: 0.5rem;
                    }

                    
                }

                .tags{
                    cursor: pointer;
                }
            }

         
            
            

        }
        .page {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: #666666;
                margin-top: 1rem;
                cursor: pointer;
            }
       
    }
    .preview{
        margin-top: 20px;
        background-color:#FFFFFF;
        padding: 1rem;
    }
   

   
    
}
</style>