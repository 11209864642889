
// 导入api
import {
  reqUserLogin,
  reqUserRegister,
  reqUserLogout

} from "@/api";


import { setToken, getToken, removeToken } from "@/utils/token";
import {setCache,getCache,removeCache} from "@/utils/cache";

import { del } from "vue";
export default {
  namespaced: true,

  //登录与注册的模块
  state: () => ({
    token: getToken()?? "", // token
    userInfo: getCache('userInfo') ?? {}, // 用户信息
    isLogin: getCache('isLogin') ?? false, // 是否登录

  }),
  // 
  mutations: {
    token(state, token) {
       
      state.token = token;
      //持久化存储token
      setToken(token);
     
    },
    userInfo(state, userInfo) {
      
      state.userInfo = userInfo;
      // 持久化存储用户信息
      setCache('userInfo',state.userInfo);
      
    },
    isLogin(state, isLogin) {
      
      state.isLogin = isLogin;
      // 持久化存储登录标识
      setCache('isLogin',isLogin);
    },
    /**
     * 删除用户信息
     */
    deleteUserInfo(state) {
      console.log("删除用户信息");
        // commit("token", '');
        // commit("userInfo", {});
        // commit("isLogin", false);
        removeToken();
        removeCache('isLogin',false);
        removeCache('userInfo');
    }



  },

  actions: {
    /**
      * 登录
      * @param {*} param0 
      * @param {*} data 
      * @returns 
      */
    async authLogin({ commit }, data) {
      let result = await reqUserLogin(data);

      if (result.code == 201) {
        console.log(result);
        //用户已经登录成功且获取到token
        commit("token", result.data.token);
        
        commit("userInfo",  JSON.stringify(result.data.userInfo));
        commit("isLogin", true);
        
  
        
      } else {
       
        commit('deleteUserInfo')
      }

      return result.status;

    },

    /**
     * 登出
     * @param {*} param0 
     */
    async logout({ commit }) {

      let result = await reqUserLogout();
      if (result.status == 'success') {
        commit('deleteUserInfo')
        return result.status;
      }
      return "error"
    },

   
  },
  // 计算属性
  getters: {
    getUserInfo(state){
      return JSON.parse(state.userInfo)
    }

  }
}



